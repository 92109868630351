import React from "react";
import SEO from "@src/components/SEO";
import window from "global/window";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

export default function OIDCCallback() {
  useRedirectHandler(() => {
    return `https://api.classdojo.com/api/oidc/callback${window.location.search}`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
}
